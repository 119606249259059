import { GetServerSideProps, InferGetServerSidePropsType } from "next";
import { NextSeo } from "next-seo";
import Head from "next/head";
import Script from "next/script";
import { useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import Layout from "~/components/Layout";
import SEOTags from "~/components/SEOTags";
import { QUERY_GET_CMS_PAGE } from "~/data/cms/gql";
import { GET_STORE_CONFIG_ON_DOCUMENT } from "~/data/home";
import storeConfigAtom from "~/services/store-config/store-config.atom";
import ReMaketing, { REMARKETING_HOME } from "~/services/tracking/ReMaketing";
import styles from "~/styles/pages/Home.module.scss";
import { initializeApollo } from "~/utils/apollo-client";
import { getPageStruct, renderPage } from "~/utils/page";
import { getTheme } from "~/utils/theme";

const Home = ({
  meta,
  storeConfig,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const pageStruct = getPageStruct(getTheme(), "home");
  const [component, setComponent] = useState<any>();

  const dataApi: any = useMemo(() => {
    return {};
  }, []);

  useEffect(() => {
    async function fetchComponent() {
      try {
        const fetchedComponents = await renderPage(
          pageStruct.children.sort((a: any, b: any) => a.order - b.order),
          getTheme()
        );

        // Filter and validate components
        setComponent(fetchedComponents.filter((c: any) => !!c));
      } catch (error) {
        console.error("Error fetching components:", error);
      }
    }

    fetchComponent();
  }, [pageStruct.children]);

  const newTitle = [
    process.env.NEXT_PUBLIC_SEO_DEFAULT_BRAND,
    process.env.NEXT_PUBLIC_SEO_DEFAULT_TITLE,
  ]
    .filter((i) => !!i)
    .join(" | ");

  const seoConfig = {
    "@context": "https://schema.org/",
    "@graph": [
      {
        "@type": "Store",
        "@context": "https://schema.org",
        image: storeConfig?.frontend_config_logo,
        paymentAccepted: [
          "http://purl.org/goodrelations/v1#Cash",
          "http://purl.org/goodrelations/v1#ByBankTransferInAdvance",
        ],
        currenciesAccepted: "USD",
        founder: {
          "@type": "Person",
          "@id": "https://dunkare.com/#person",
          name: "James Ginny",
          sameAs: ["https://x.com/GinnyJames112"],
          knowsLanguage: ["English"],
          description:
            "James Ginny founded Dunkare in Delaware to create stylish shirts that perfectly match sneakers, blending his passion for streetwear fashion and sneaker culture.",
          jobTitle: "Founder",
          gender: "https://schema.org/Male",
          image:
            "https://dunkare-api.canawan.com/media/catalog/category/Ginny_James.jpg",
        },
        employee: {
          "@id": "https://dunkare.com/#person",
        },
        publicAccess: "true",
        latitude: 39.1573471,
        longitude: -75.5242788,
        openingHoursSpecification: [
          {
            "@type": "OpeningHoursSpecification",
            opens: "07:00:00",
            closes: "23:00:00",
            dayOfWeek: "https://schema.org/Monday",
          },
          {
            "@type": "OpeningHoursSpecification",
            closes: "23:00:00",
            dayOfWeek: "https://schema.org/Tuesday",
            opens: "07:00:00",
          },
          {
            "@type": "OpeningHoursSpecification",
            closes: "23:00:00",
            dayOfWeek: "https://schema.org/Wednesday",
            opens: "07:00:00",
          },
          {
            "@type": "OpeningHoursSpecification",
            closes: "23:00:00",
            dayOfWeek: "https://schema.org/Thursday",
            opens: "07:00:00",
          },
          {
            "@type": "OpeningHoursSpecification",
            closes: "23:00:00",
            dayOfWeek: "https://schema.org/Friday",
            opens: "07:00:00",
          },
          {
            "@type": "OpeningHoursSpecification",
            closes: "23:00:00",
            dayOfWeek: "https://schema.org/Saturday",
            opens: "07:00:00",
          },
          {
            "@type": "OpeningHoursSpecification",
            closes: "23:00:00",
            dayOfWeek: "https://schema.org/Sunday",
            opens: "07:00:00",
          },
        ],
        sameAs: [
          "https://www.google.com/search?q=Dunkare",
          "https://www.facebook.com/brand.dunkare",
          "https://www.instagram.com/dunkare_brand",
          "https://www.tiktok.com/@brand.dunkare",
          "https://www.pinterest.com/dunkarebrand/",
          "https://www.youtube.com/@Brand.Dunkare",
          "https://x.com/dunkare_brand",
        ],
        url: "https://dunkare.com/",
        "@id": "https://dunkare.com/#organization",
        potentialAction: {
          result: {
            "@type": "Reservation",
            name: "Checkout",
          },
          "@type": "ConsumeAction",
          target: [
            {
              "@type": "EntryPoint",
              inLanguage: "en",
              actionPlatform: [
                "http://schema.org/DesktopWebPlatform",
                "http://schema.org/IOSPlatform",
                "http://schema.org/AndroidPlatform",
              ],
              urlTemplate: "https://dunkare.com/carts",
            },
          ],
        },
        name: newTitle,
        alternatename: process.env.NEXT_PUBLIC_SEO_DEFAULT_BRAND,
        description: meta?.meta_description,
        disambiguatingdescription:
          process.env.NEXT_PUBLIC_SEO_DEFAULT_DISAMBIGUATING,
        logo: storeConfig?.frontend_config_logo,
        slogan: process.env.NEXT_PUBLIC_SEO_DEFAULT_TITLE,
        priceRange: "23.99USD - 32.99USD",
        hasMap: ["https://maps.app.goo.gl/j4rzLANNjadfiLRUA"],
        mainEntityOfPage: "https://dunkare.com/",
        email: storeConfig?.tracking_support_email,
        contactPoint: {
          "@type": "ContactPoint",
          telephone: "3022615295",
          contactType: "Customer support",
          email: storeConfig?.tracking_support_email,
        },
        telephone: "3022615295",
        address: {
          "@type": "PostalAddress",
          addressCountry: "US",
          addressLocality: "Dover",
          addressRegion: "Delaware",
          streetAddress: "8 The Green A",
          postalCode: "19901",
        },
        foundingLocation: {
          "@type": "Place",
          name: "Dover",
          url: "https://en.wikipedia.org/wiki/Dover,_Delaware",
          address: "United States",
        },
        geo: {
          "@type": "GeoCoordinates",
          latitude: 39.15755921345033,
          longitude: -75.52350632697237,
        },
        legalName: "Dunkare LLC",
        foundingDate: "2024-02-21",
        numberOfEmployees: "100",
        hasOfferCatalog: {
          "@type": "OfferCatalog",
          itemListElement: [
            {
              "@type": "Offer",
              name: "Clothing",
              itemOffered: "https://dunkare.com/categories/clothing",
              url: "https://dunkare.com/categories/clothing",
            },
            {
              "@type": "Offer",
              name: "Accessories",
              itemOffered: "https://dunkare.com/categories/accessories",
              url: "https://dunkare.com/categories/accessories",
            },
          ],
        },
        areaServed: {
          "@type": "Country",
          url: "https://www.wikidata.org/wiki/Q30",
          name: "United States of America",
        },
        alumni: [
          {
            "@id": "https://dunkare.com/#person",
          },
        ],
        sponsor: [
          {
            "@id": "https://dunkare.com/#person",
          },
        ],
        brand: {
          "@type": "Organization",
          name: process.env.NEXT_PUBLIC_SEO_DEFAULT_BRAND,
          url: process.env.NEXT_PUBLIC_URL,
          logo: storeConfig?.frontend_config_logo,
          contactPoint: {
            "@type": "ContactPoint",
            telephone: "3022615295",
            contactType: "Customer Service",
            email: storeConfig?.tracking_support_email,
            areaServed: "United States of America",
          },
          location: {
            "@type": "PostalAddress",
            addressCountry: "USA",
            name: newTitle,
            addressLocality: "Dover",
            addressRegion: "Delaware",
            streetAddress: "8 The Green A",
            postalCode: "19901",
          },
        },
      },
    ],
  };

  return (
    <Layout isUseMinHeight>
      <SEOTags
        metaTitle={newTitle}
        description={meta?.meta_description}
        keywords={meta?.meta_keywords}
      />
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(seoConfig) }}
        >
          {}
        </script>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebSite",
              url: "https://dunkare.com/",
              potentialAction: {
                "@type": "SearchAction",
                target: {
                  "@type": "EntryPoint",
                  urlTemplate:
                    "https://dunkare.com/search?keyword={search_term_string}&utm_campaign=action_schema_markup",
                },
                "query-input": "required name=search_term_string",
              },
            }),
          }}
        ></script>
      </Head>
      {/* <NextSeo openGraph={seoConfig} /> */}
      <ReMaketing gtagType={REMARKETING_HOME} />
      <div className={styles.homepage}>
        {component?.map((com: any, index: number) => {
          const key = pageStruct.children[index]?.dataKey; // Assuming each child has a key matching SectionsKey
          const props = dataApi[key] || {}; // Get the props for the component from dataApi
          // if (typeof Component !== 'function' && typeof Component !== 'string') {
          //   console.error("Invalid component:", Component);
          //   return null;
          // }
          return {
            ...com,
            props: {
              ...com.props,
              ...props,
            },
          };
        })}
      </div>
      <div></div>
    </Layout>
  );
};

export default Home;

export const getServerSideProps: GetServerSideProps = async () => {
  const apolloClient = initializeApollo({
    httpLinkProps: { useGETForQueries: true },
  });
  try {
    const { data } = await apolloClient.query({
      query: QUERY_GET_CMS_PAGE,
      variables: {
        identifier: "home",
      },
    });
    const { data: dataStoreConfig } = await apolloClient.query({
      query: GET_STORE_CONFIG_ON_DOCUMENT,
      variables: {
        identifier: "home",
      },
    });

    return {
      props: {
        meta: data?.cmsPage,
        storeConfig: dataStoreConfig?.storeConfig,
      },
    };
  } catch (error) {
    return {
      props: {
        meta: {},
        storeConfig: {},
      },
    };
  }
};
